import * as React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import Markdown from 'react-markdown';
import { Link } from 'gatsby';
import {
  Flex, Box, Button as BaseButton, Image,
} from 'rebass/styled-components';
import Container from '../components/shared/Container';
import { DesktopOnly, MobileOnly } from '../components/shared/media';
import background from '../images/background.svg';
import backgroundMobile from '../images/background_mobile.svg';
import beyondSoftware from '../images/beyond_software.svg';
import { useLocale } from '../hooks';
import SEO from '../components/shared/SEO';
import juno from '../images/ourwork/juno.svg';
import newsuk from '../images/ourwork/newsuk.svg';
import insurami from '../images/ourwork/insurami.png';
import handshake from '../images/illustrations/handshake.svg';
import conversation from '../images/illustrations/conversation.svg';

const JumotronOuter = styled.div`
  position: relative;
`;

const JumotronInner = styled.div`
  position: relative;
  z-index: 1;
`;

const BackgroundImgOuter = styled.div`
  z-index: 0;
  position: absolute;
  right: 0;
  top: 24px;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-image: linear-gradient(to right, #fff 0%, #fff 35%, rgba(255,255,255,0.4) 100%);
  }
  @media screen and (min-width: 52rem) {
    top: 32px;
    &::after {
      background-image: linear-gradient(to right bottom, #fff 0%, #fff 25%, rgba(255,255,255,0.1) 100%);
    }
  }
`;

const BeyondSoftwareImgOuter = styled.div`
  z-index: 0;
  position: relative;
  right: 0;
  top: 0;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-image: linear-gradient(to right, rgba(255,255,255,0) 50%, #fff 95%);
  }
`;

const Button = styled(BaseButton)`
  cursor: pointer;
`;

const HoverableImg = styled(Image)`
  transition: transform 0.3s;
  &:hover {
    opacity: 0.8;
    transform: scale(1.1);
  }
`;

const Section = ({
  align = 'left', img, heading, body, widthSplit = [0.5, 0.5],
}) => (
  <>
    {align === 'left' && (
      <Flex flexDirection={['column', 'row']}>
        <Box width={[1, widthSplit[0]]} pr={['0px', '16px']}>
          <Box mt={['24px', '0px']} mb="24px">
            <h3>{heading}</h3>
          </Box>
          {typeof body === 'string' && <Markdown source={body} />}
          {typeof body !== 'string' && body}
        </Box>
        <Box width={[1, widthSplit[1]]} mt={['24px', '0px']} pl={['0px', '16px']}>
          <Flex height="100%" alignItems="center" justifyContent="center">
            {img}
          </Flex>
        </Box>
      </Flex>
    )}
    {align === 'right' && (
      <Flex flexDirection={['column', 'row']}>
        <Box display={['none', 'block']} width={[1, widthSplit[1]]} pr={['0px', '16px']}>
          <Flex height="100%" alignItems="center" justifyContent="center">
            {img}
          </Flex>
        </Box>
        <Box width={[1, widthSplit[0]]} pl={['0px', '16px']}>
          <Box mt={['24px', '0px']} mb="24px">
            <h3>{heading}</h3>
          </Box>
          {typeof body === 'string' && <Markdown source={body} />}
          {typeof body !== 'string' && body}
        </Box>
        <Box display={['block', 'none']} width={[1, widthSplit[1]]} mt={['24px', '0px']} pl={['0px', '16px']}>
          <Flex height="100%" alignItems="center" justifyContent="center">
            {img}
          </Flex>
        </Box>
      </Flex>
    )}
  </>
);

const Home = injectIntl(({ intl }) => {
  const locale = useLocale(['en', 'de']);
  const pathPrefix = locale === 'en' ? '' : '/de';
  return (
    <div>
      <SEO title={`Tugboat Coding - ${intl.formatMessage({ id: 'home.jumbotron.heading' })}`} />
      <JumotronOuter>
        <BackgroundImgOuter>
          <MobileOnly>
            <HoverableImg alt="Background" src={backgroundMobile} width="100%" />
          </MobileOnly>
          <DesktopOnly>
            <HoverableImg alt="Background" src={background} width="100%" />
          </DesktopOnly>
        </BackgroundImgOuter>
        <JumotronInner>
          <Box pt="72px">
            <h1>{intl.formatMessage({ id: 'home.jumbotron.heading' })}</h1>
          </Box>
          <Box pt="48px" pb="72px">
            <a href={`${pathPrefix}/about`}>
              <Button variant="primary">
                {intl.formatMessage({ id: 'home.jumbotron.cta' })}
              </Button>
            </a>
          </Box>
        </JumotronInner>
      </JumotronOuter>
      <Box my={['72px', '144px']}>
        <Box mb="32px">
          <Flex justifyContent="center">
            <h3>{intl.formatMessage({ id: 'home.trusted_by.heading' })}</h3>
          </Flex>
        </Box>
        <Link to="/our-work">
          <Box mb="24px">
            <Flex justifyContent="center">
              <Box mt={['2px', '4px']} mr={['16px', '32px']}>
                <HoverableImg alt="Juno Bio" src={juno} height={['24px', '40px']} />
              </Box>
              <Box mt="-2px" mr={['16px', '32px']}>
                <HoverableImg alt="News UK" src={newsuk} height={['19px', '32px']} />
              </Box>
              <HoverableImg alt="Insurami" src={insurami} height={['24px', '40px']} />
            </Flex>
          </Box>
        </Link>
        <Flex justifyContent="center">
          <Link to="/our-work">
            <Button variant="primary">
              {intl.formatMessage({ id: 'home.trusted_by.cta' })}
            </Button>
          </Link>
        </Flex>
      </Box>
      <Box mb="48px">
        <Section
          heading={intl.formatMessage({ id: 'home.built_on_trust.heading' })}
          body={intl.formatMessage({ id: 'home.built_on_trust.body' })}
          img={<HoverableImg alt="Trust" src={handshake} height={['160px', '240px']} />}
        />
      </Box>
      <Box mb="48px">
        <Section
          align="right"
          heading={intl.formatMessage({ id: 'home.full_stack.heading' })}
          body={(
            <>
              <Box mb="24px">
                <Markdown source={intl.formatMessage({ id: 'home.full_stack.body' })} />
              </Box>
              <Link to="/about">
                <Button variant="primary">
                  {intl.formatMessage({ id: 'home.full_stack.cta' })}
                </Button>
              </Link>
            </>
          )}
          img={<HoverableImg alt="Trust" src={background} height={['160px', '240px']} />}
        />
      </Box>
      <Box mb="48px">
        <Section
          heading={intl.formatMessage({ id: 'home.beyond_software.heading' })}
          body={intl.formatMessage({ id: 'home.beyond_software.body' })}
          img={(
            <BeyondSoftwareImgOuter>
              <HoverableImg alt="Beyond software" src={beyondSoftware} height={['160px', '240px']} />
            </BeyondSoftwareImgOuter>
          )}
        />
      </Box>
      <Box mb="48px">
        <Section
          align="right"
          heading={intl.formatMessage({ id: 'home.get_in_touch.heading' })}
          body={(
            <>
              <Box mb="24px">
                <Markdown source={intl.formatMessage({ id: 'home.get_in_touch.body' })} />
              </Box>
              <Link to="/contact">
                <Button variant="primary">
                  {intl.formatMessage({ id: 'home.get_in_touch.cta' })}
                </Button>
              </Link>
            </>
          )}
          img={<HoverableImg alt="Trust" src={conversation} height={['200px', '240px']} />}
        />
      </Box>
    </div>
  );
});

const IndexPage = () => (
  <Container>
    <Home />
  </Container>
);

export default IndexPage;
